
:root {
  --green: #078d24;
}

.page {
  padding: 0cm 2cm !important;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.header {
  padding: 7px 0px;
  height: 1.25cm;
  border-bottom: 2px solid var(--green);
  border-top: 2px solid var(--green);
}

.options {
  padding: 0px 5px;
  font-size: 15px;
  height: 29.7cm;
  overflow-y: scroll;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.option-header {
  padding: .75rem 1.25rem;
  margin-bottom: 5px;
  margin-top: 15px;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  font-weight: 900px !important;
}

input {
  padding: 10px;
  width: 50%;
  height: 35px;
}

.textRight {
  text-align: end;
}

.textLeft {
  text-align: start;
}

.greenColor {
  color: var(--green);
}

.squaree {
  width: 60px;
  height: 30px;
  padding: 2px;
  text-align: center;
  border: 1px solid var(--green);
}

.button {
  width: 100%;
}

