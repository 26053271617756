.page {
  padding: 1cm 2cm !important;
  margin: 0px auto !important;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.headerLine {
  padding: 10px 0;
  border: 1px solid black;
  border-radius: 5px;
  color: white  ;
}

.options {
  padding: 0px 5px;
  font-size: 15px;
  height: 29.7cm;
  overflow-y: scroll;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.option-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 5px;
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 900px !important;
}

input {
  padding: 10px;
  width: 50%;
  height: 35px;
}

.bold {
  font-weight: bold;
}

.tableHead {
  background-color: #eb144c;
  color: black;
}

.tableHead th {
  padding: 15px;
}
.button {
  width: 100%;
}
@media print {
  .page {
    width: 100% !important;
    height: 100% !important;
  }
}
