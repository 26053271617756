body {
  padding-top: 0 !important;
  background-color: transparent !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@page {
  size: auto !important;
}

.shadowed:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.btn-dollar {
  background: linear-gradient(
    90deg,
    rgba(30, 111, 195, 1) 0%,
    rgba(45, 156, 244, 1) 100%
  );
  color: white !important;
}

.btn-iqd {
  background: linear-gradient(
    90deg,
    rgba(30, 111, 195, 1) 0%,
    rgba(45, 156, 244, 1) 100%
  );
  color: white !important;
}
.bg-newCard {
  color: #fff;
  background: linear-gradient(
    90deg,
    rgba(86, 0, 146, 1) 0%,
    rgba(108, 1, 183, 1) 47%,
    rgba(110, 4, 185, 1) 100%
  ) !important;
}

.newSafeBox,
.newSellReturnBox-dashboard {
  color: white;
  background: linear-gradient(
    90deg,
    rgb(30, 109, 194) 0%,
    rgba(45, 156, 244, 1) 100%
  );
}

.last-login-dashboard,
.bar-dashbord {
  color: white !important;
  background: linear-gradient(45deg, #3491e5, #3491e5);
}
.last-login-dashboard:checked {
  background-color: red;
}
.blured {
  /* filter: blur(4px); */
  backdrop-filter: blur(15px);
  backdrop-filter: brightness(120%);
}
.hoverd:hover {
  backdrop-filter: brightness(120%);
}
.selected {
  backdrop-filter: brightness(80%);
}
/* .Bar-dashboard:hover {
  backdrop-filter: brightness(120%);
} */
.searchInput-appBar::placeholder {
  color: white !important;
}
.noMargin {
  padding: 0px !important;
}
.sideBarList {
  background: rgb(30, 109, 194) !important;
  color: white !important;
}

.IconMenu {
  color: white;
  margin-right: 3px;
}
/* ?Sherko voucher css */

:root {
  --color-primary: #3b71ca;
  --color-secondary: #9fa6b2;
  --color-success: #14a44d;
  --color-danger: #20;
  --color-warning: #e4a11b;
  --color-info: #54b4d3;
  --color-light: #fbfbfb;
  --color-dark: #332d2d;
}
.hr-voucher {
  border: 1px solid var(--color-success) !important;
}
.text-color {
  color: var(--color-success);
}
.bg-color {
  background-color: var(--color-success);
}
.logo {
  width: 100px;
}
.border-down {
  border-bottom: 2px dotted var(--color-success);
}
.rounded {
  border-radius: 10px;
}
.note-voucher {
  text-align: justify;
  text-justify: inter-word;
}
.colorPicker {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
  padding: 5px;
  transition: all 0.2s ease-in-out;
}
.colorPicker:hover {
  transform: scale(1.15);
}
.bordered-color {
  /* filter: brightness(85%); */
  border: 1px solid black;
}
/* ?End Sherko voucher css */
.input-voucher {
  padding: 0;
  width: 150%;
  height: 150%;
  margin: -25%;
}

.cp_wrapper {
  overflow: hidden;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin: 0 20px;
  box-shadow: 1px 1px 3px 0px grey;
}
