#animationButton {
  animation: alert 1.3s infinite ease-in-out;
}

@keyframes alert {
  from {
    background-color: rgb(164, 15, 15);
    color: white;
  }
  to {
    color: black;
    background-color: rgb(255, 255, 255);
  }
}
