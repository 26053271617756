.rdt,
.rdtOpen {
  width: 175px !important;
}

@font-face {
  font-family: "Speda";
  src: url("./fonts/Speda.ttf") format("truetype");
}

@font-face {
  font-family: "NRT";
  src: url("./fonts/NRT-Reg.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/nunito.ttf") format("truetype");
}

*:not(i) {
  font-family: Nunito, Speda, NRT, serif;
}

.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  border-bottom: 2px solid #e0e0e0 !important;
}

.mainMenuItem {
  font-size: 14px;
  word-wrap: anywhere;
  width: 160px;
}

.css-1mk9mw3-MuiList-root {
  margin-inline-start: 15px !important;
}

.mainMenuIcon {
  min-width: 25px !important;
}

/* Dashboard */
.textWhite {
  color: white !important;
}
.bgWhite {
  background-color: white !important;
}
.radius {
  border-radius: 25px !important;
}
.noBorder {
  border: none !important;
}
.dashboardCard1 {
  background-image: url(./dashboardImages/wave1.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard2 {
  background-image: url(./dashboardImages/wave2.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard3 {
  background-image: url(./dashboardImages/wave3.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard4 {
  background-image: url(./dashboardImages/wave4.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard5 {
  background-image: url(./dashboardImages/cashierCard1.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard6 {
  background-image: url(./dashboardImages/cashierCard2.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard7 {
  background-image: url(./dashboardImages/cashierCard3.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard8 {
  background-image: url(./dashboardImages/cashierCard4.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.dashboardCard9 {
  background-image: url(./dashboardImages/cashierCard5.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
/* .dashboardCard10{
  background-image: url(./dashboardImages/cashierCard6.png) !important;
  background-size: cover  !important;
  background-position: center;
} */
.welcomeBackgroundImg {
  background-image: url(./dashboardImages/welcImg.png) !important;
  background-size: cover !important;
  background-position: center !important;
}

.tabelCard {
  border-radius: 20px !important;
  border: none !important;
}

.carousel-inner {
  border-radius: 25px !important;
  border: none !important;
}

.carousel-control-prev {
  display: none !important;
  /* background-color: #000 !important;
    border-radius: 25px !important; */
}

.carousel-control-next {
  display: none !important;
  /* background-color: #000 !important;
    border-radius: 25px !important; */
}

.staWidth {
  height: 453px !important;
  overflow: auto !important;
}

.RaMenuItemLink-active-46 {
  color: rgba(0, 0, 0, 0.87);
  background-color: aliceblue !important;
  border-radius: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  color: black !important;
}

.carousel-item {
  width: 24rem !important;
}

.carousel {
  width: 24rem !important;
}

.loginImg {
  background-image: url("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg") !important;
  background-size: cover !important;
}

.sellSwitch {
  /* display: inline-flex !important; */
}

.flexWrapRow {
  flex-wrap: nowrap !important;
}
.overflowX {
  overflow-x: scroll !important;
}
.customHeight {
  height: 115px !important;
}

.RaLayout-content-4 {
  background-image: url(./dashboardImages/wave1.png) !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}
@page {
  size: auto !important;
}
